@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './styles/tailwind.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* hidden arrow in input tag, type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* ct: custom - tiền tố - tránh trùng với css của tailwind */

.ct-text-link {
  @apply relative inline-block pb-3;
}

.ct-underline-link {
  @apply absolute bottom-0 left-0 w-full h-1 rounded-sm transform scale-x-0 origin-bottom transition-transform duration-300 ease-in-out group-hover:scale-x-100;
}

.ct-underline-link-active {
  @apply absolute bottom-0 left-0 w-full h-1 rounded-sm transform origin-bottom scale-x-100;
}

.ct-text-title {
  @apply font-bold uppercase text-xl lg:text-3xl 2xl:text-5xl ct-text-link text-[#367AE2];
}

.ct-underline-title {
  @apply absolute bottom-0 left-0 w-full h-1 bg-[#1A49A5] rounded-sm transform origin-bottom transition-transform duration-300 scale-x-100;
}

.ct-sub-container-home {
  @apply bg-white rounded-2xl p-2 m-2 shadow-md shadow-sky-200;
}

.ct-item-companion {
  @apply flex flex-col items-center justify-center rounded-lg p-1;
}

.ct-img-item-companion {
  @apply p-3 h-32 w-auto;
}

.ct-text-item-companion {
  @apply text-center font-semibold text-white mt-2 text-lg 2xl:text-3xl;
}

/* Định dạng kiểu của nút prev */
.slick-next::before {
  color: #367AE2 !important;
}
.slick-next {
  color: #367AE2 !important;
  right: -20px !important;
}

/* Định dạng kiểu của nút next (nếu cần) */
.slick-prev::before {
  color: #367AE2 !important;
}
.slick-prev {
  color: #367AE2 !important;
  left: -20px !important;
}

.slick-prev:hover,
.slick-next:hover {
  color: #1761d0 !important;
}

.slick-dots {
  display: none !important;
}

.ql-align-center {
  text-align: center !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ql-align-left {
  text-align: left !important;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
}

.ql-align-right {
  text-align: right !important;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: end;
}

.ql-align-justify {
  text-align: justify !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.news-slider-text-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-slider-text-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}